import Check from 'public/icons/figma/check.svg';
import CloseBig from 'public/icons/figma/close-big.svg';
import CreditCard from 'public/icons/figma/credit-card.svg';
import History from 'public/icons/figma/history.svg';
import Lock from 'public/icons/figma/lock.svg';
import SearchSmallIcon from 'public/icons/figma/search-small.svg';
import ShareIOS from 'public/icons/figma/share-ios.svg';

// TODO - Rename this const to be more generic as per form_types
export const BlueshiftEvents = {
  GET_WALLET_LINK: 'get_wallet_link',
  CONTACT_US: 'contact_us_form',
  PATIENT_REGISTRATION: 'patient_registration_form',
  PATIENT_ORIENTATION: 'patient_orientation_form',
  ABANDONED_CART: 'abandoned_cart',
  REWARDS_SIGNUP: 'rewards_signup',
  SIGNUP: 'rise_account_form',
};

export const SegmentEvents = {
  FORM_SUBMITTED: 'Form Submitted',
  PRODUCT_VIEWED: 'Product Viewed',
  OFFER_SENT: 'Offers Sent',
  CART_ABANDONED: 'Cart Abandoned',
  REWARDS_SIGNUP: 'Rewards Signup',
  CUSOMER_SUBSCRIBED: 'Customer Subscribed',
  FORM_CLOSED: 'Form Closed',
  MENU_CHANGED: 'Menu Changed',
  NAVIGATION_CLICKED: 'Navigation Clicked',
  NAVIGATION_EXPANDED: 'Navigation Expanded',
  CATEGORIES_CLICKED: 'Categories Clicked',
  CATEGORIES_EXPANDED: 'Categories Expanded',
  AGE_CONSENTED: 'Age Consented',
  BANNER_CLICKED: 'Banner Clicked',
  BANNER_VIEWED: 'Banner Viewed',
  CART_VIEWED: 'Cart Viewed',
  PRODUCT_SELECTED: 'Product Selected',
  PRODUCT_ADDED_TO_CART: 'Product Added To Cart',
  CARD_LIST_SORTED: 'Card List Sorted',
  BANNER_CLOSED: 'Banner Closed',
  BANNER_SCROLLED: 'Banner Scrolled',
  DISPENSARY_CHANGED: 'Dispensary Changed',
  COMMUNICATION_INITIATED: 'Communication Initiated',
  CARD_LIST_CLICKED: 'Card List Clicked',
  CARD_LIST_FILTERED: 'Card List Filtered',
  CARD_LIST_VIEWED: 'Card List Viewed',
  SIGN_OUT: 'Signed Out',
  SIGN_IN: 'Signed In',
  SIGNUP_ERROR: 'Signup Error',
  CHECKOUT_INITIATED: 'Checkout Initiated',
  ACCOUNT_CREATED: 'Rise Account Created',
  SIGUNP_INITIATED: 'Signup Initiated',
  CHAT_LAUNCHED: 'Chat Launched',
  CHECKOUT_COMPLETED: 'Checkout Completed',
  SIGNUP_STAGE: 'Signup Drawer Opened',
};

export const assistance = [
  { title: 'Getting A Medical Card', icon: CreditCard },
  { title: 'Renewing My Medical Card', icon: History },
  { title: 'Something Else', icon: SearchSmallIcon },
];
export const packagingPreferenceItems = [
  { title: 'Child Resistant', icon: Lock },
  { title: 'Easy Open', icon: ShareIOS },
  { title: 'No Preference', icon: CloseBig },
];

export const howDidYouFindUsItems = [
  'Google',
  'Leafly',
  'Weedmaps',
  'Yelp',
  'Referral/Friend',
  'News Article',
  'Doctor’s Recommendation',
  'Others',
];

export const howDidYouHearAboutUsItems = [
  'Google',
  'Facebook',
  'Instagram',
  'Word Of Mouth',
  'Doctor’s Recommendation',
  'Potguide',
  'Leafly',
  'Weedmaps',
  'Drive/Walk By',
  'Others',
];

export const radioOptionYesNo = [
  { title: 'Yes', icon: Check },
  { title: 'No', icon: CloseBig },
];
