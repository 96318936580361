export const WEIGHT_OBJECT = {
  gram: '1g',
  'half gram': '1/2g',
  'two gram': '2g',
  'eighth ounce': '3.5g',
  'quarter ounce': '7g',
  'half ounce': '14g',
  ounce: '28g',
};

export const PRICE_BUCKET_FILTER_ITEMS = [
  { label: 'Under $20', value: ':19.99', end: 19.99 },
  { label: '$20-$40', value: '20:39.99', start: 20, end: 39.99 },
  { label: '$40-$60', value: '40:59.99', start: 40, end: 59.99 },
  { label: '$60-$80', value: '60:79.99', start: 60, end: 79.99 },
  { label: 'Above $80', value: '80:', start: 80 },
];

export const CATEGORY_SEPARATOR = ':';

export const NO_PRODUCTS_RESULTS_MESSAGE =
  "Bummer. No exact matches found. Try a similar search with new keywords and hopefully we'll find something for you.";
