import React, { useState } from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import CloseIcon from 'public/icons/figma/close-small.svg';
import SearchIcon from 'public/icons/figma/search-small.svg';
import { InputTextContainer, SearchBox, SearchContainer, SearchInput, SearchInputContainer } from './styles';

interface Tprops {
  onClose(): void;
}

const SearchBar = ({ onClose }: Tprops) => {
  const [isDesktop] = useMediaQuery(tablet);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLowerCase, setIsLowerCase] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    window.location.href = `/search?query=${encodeURIComponent(searchKeyword)}`;
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    setIsLowerCase(value.toLowerCase() === value);
  };
  return (
    <SearchContainer>
      <SearchInputContainer id="header-search-input">
        <IconButton
          data-testid="search-button"
          button-type="primary"
          color="green"
          className={'!items-center !justify-center !rounded-[32px] !bg-kief !text-leaf'}
          onClick={handleClick}
        >
          <SearchIcon />
        </IconButton>
        <InputTextContainer>
          <SearchBox role="search" onSubmit={handleClick} $isLowerCase={isLowerCase}>
            <SearchInput type="text" id="large-input" placeholder="SEARCH SOMETHING" onChange={handleChange} />
          </SearchBox>
        </InputTextContainer>
        {isDesktop ? (
          <IconWithTextButton
            data-testid="close-button"
            iconPosition="after"
            color="green"
            button-type="secondary"
            icon={<CloseIcon />}
            onClick={onClose}
            size="sm"
          >
            Close
          </IconWithTextButton>
        ) : (
          <IconButton data-testid="close-button-icon" onClick={onClose} button-type={'primary'} color={'white'}>
            <CloseIcon />
          </IconButton>
        )}
      </SearchInputContainer>
    </SearchContainer>
  );
};

export default SearchBar;
